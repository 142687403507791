var app;

(function () {
    'use strict';

    var document,
        $ = jQuery.noConflict();

    var s;
    app = {

        settings: {
            // Properties
            version: '1.0.0',
            author: 'http://matthieu-schneider.fr',
            timerStart: Date.now(),

            // Debug
            debug: false,
            alertFallback: false,
            debugLoadMessages: [],
            debugEventMessages: []
        },

        // Initialisation
        init: function () {
            var self = this,
                s = self.settings;

            // Fallback ie8 for console.log()
            if (typeof console === "undefined" || typeof console.log === "undefined") {
                console = {};
                if (s.alertFallback) {
                    console.log = function (msg) {
                        alert(msg);
                    };
                }
                else {
                    console.log = function () { };
                }
                console.clear = function () { };
            }

            console.log('%cInformations\nversion: ' + s.version + '\nauthor :' + s.author, 'color: #ae81bc');

            // Calculate time to load DOMready and everything loaded
            $(document).ready(function () {
                if (s.debug) console.log('%cTime until DOMready : ' + (Date.now() - s.timerStart) / 1000 + 'sec', 'color: #69b2b8');
            });

            $(window).load(function () {
                if (s.debug) console.log('%cTime until everything loaded : ' + (Date.now() - s.timerStart) / 1000 + 'sec', 'color: #69b2b8');

                // All is loaded
                self.loaded();
            });

            $('.lazy').Lazy({
                threshold: 50,
                effect: 'fadeIn',
                effectTime: 750
            });
        },

        // All is loaded
        loaded: function () {

            var self = this;

            self.smoothScrolling();

            $('.loading').fadeOut('slow', function () {
                $(this).remove();
            });
        },

        // debugEvent
        console: function (messages) {
            var self = this,
                s = self.settings;

            if (s.debug) {
                var date = new Date();

                s.debugEventMessages.push(messages);

                console.clear();

                console.log('%cEvent - ' + date.getHours() + 'h' + date.getMinutes() + 'mn' + date.getSeconds() + 's', 'color: #a6c628');
                for (var key in s.debugEventMessages) {
                    if (typeof s.debugEventMessages[key] === 'object') {
                        console.log(s.debugEventMessages[key]);
                    } else {
                        console.log('%c→ ' + s.debugEventMessages[key], 'color: #fd971f');
                    }
                }
                console.log('%cEnd', 'color: #a6c628');
            } else {
                return;
            }
        },

        smoothScrolling: function () {
            $('a[href*=#]:not([href=#])').bind('click', function () {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
                    var target = $(this.hash);

                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 1000);
                        return false;
                    }
                }
            });
        },

        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this, args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        }
    };

    app.init();
})();