(function() {
  'use strict';

  var $ = jQuery.noConflict();

  // app.console('Sample widget!');

  var s;
  app.ContactWidget = {

    settings: {
      elContact: $('#contact'),
      isMobile : $('html').hasClass('is-mobile'),
      mapStyle: [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"color":"#f7f1df"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"color":"#d0e3b4"}]},{"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"geometry","stylers":[{"color":"#fbd3da"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#bde6ab"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"on"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffe15f"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#efd151"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"black"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"color":"#cfb2db"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a2daf2"}]}]
    },

    init: function() {
      var self = this,
          index = 0;
      s = this.settings;

      s.elContact.find('address').each(function() {
        self.createMap($(this), index);
        ++index;
      });
    },

    createMap: function(el, index) {
      el.parent().before('<div id="map-' + index + '" class="map col-xs-12 col-sm-6"></div>');

      var map = new google.maps.Map(document.getElementById('map-'+index), {
        center: {lat: el.data('lat'), lng: el.data('lng')},
        draggable: (s.isMobile) ? false : true,
        mapTypeControl: false,
        panControl: false,
        scrollwheel: false,
        streetViewControl: false,
        styles: s.mapStyle,
        zoom: 15
      });

      var customIcon = new google.maps.MarkerImage("/sites/default/themes/sparlann/assets/img/pin_2x.png", null, null, null, new google.maps.Size(54,65));
      var marker = new google.maps.Marker({
        position: {lat: el.data('lat'), lng: el.data('lng')},
        icon: customIcon,
        map: map
      });
    }

  };

  app.ContactWidget.init();
})();
