(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.HomeClientsWidget = {

    settings: {
      elClientsOwlSlider: $('.clients__carousel'),
    },

    init: function() {
      self = this;
      s = this.settings;

      s.elClientsOwlSlider.owlCarousel({
        items: 1
      });
    },

    bindUIActions: function() {
      
    }
  };

  $(window).load(function() {
    app.HomeClientsWidget.init();
  });

})();
