(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.skillWidget = {

    settings: {
      isMobile : $('html').hasClass('is-mobile'),

      elSkillPrefix: $('.skill__prefix'),
      elSkillDesc: $('.skill__desc')
    },

    init: function() {
      self = this;
      s = this.settings;

      this.bindUIActions();

      this.refreshSkillHeight();
    },

    bindUIActions: function() {
      var myEfficientResize = app.debounce(function() {
        self.refreshSkillHeight();
      }, 250);

      window.addEventListener('resize', myEfficientResize);
    },

    refreshSkillHeight: function() {
      s.elSkillPrefix.each(function() {
        var el = $(this);
        el.outerHeight(0);
        el.outerHeight(el.parent().height());
      });
    }
  };

  $(window).load(function() {
    app.skillWidget.init();
  });

})();
