(function () {
    'use strict';

    var $ = jQuery.noConflict();

    var self, s;
    app.HomeCasesWidget = {

        settings: {
            isMobile: $('html').hasClass('is-mobile'),

            elCasesTitle: $('.cases__title'),
            elCasesControllers: $('.cases__controllers-container'),
            elCasesIllustration: $('.cases__illustration'),
            elCasesImage: $('.cases__image'),
            elCasesSlider: $('.cases__slider'),
            elCasesOwlSlider: $('.cases__carousel'),
            elCasesOwlSliderItem: $('.cases__item'),
            elCasesSlideCurrent: $('.cases__current'),
            elCasesSlideLength: $('.cases__length'),
            elCasesOwlTriggerPrev: $('.cases__controller--prev'),
            elCasesOwlTriggerNext: $('.cases__controller--next')
        },

        init: function () {
            console.log('test');
            self = this;
            s = this.settings;

            this.bindUIActions();

            this.refreshCasesTopHeight();

            s.elCasesSlideLength.text(s.elCasesOwlSliderItem.length);

            s.elCasesOwlSlider.owlCarousel({
                items: 1,
                dots: false,
                onInitialized: function () {
                    self.refreshCasesBottomHeight();
                }
            });
        },

        bindUIActions: function () {
            var myEfficientResize = app.debounce(function () {
                self.refreshCasesTopHeight();
                self.refreshCasesBottomHeight();
            }, 250);

            window.addEventListener('resize', myEfficientResize);

            s.elCasesOwlTriggerPrev.bind('click', function () {
                s.elCasesOwlSlider.trigger('prev.owl.carousel');
            });
            s.elCasesOwlTriggerNext.bind('click', function () {
                s.elCasesOwlSlider.trigger('next.owl.carousel');
            });

            s.elCasesOwlSlider.on('dragged.owl.carousel', function (e) {
                s.elCasesSlideCurrent.text(e.item.index + 1);
            });

            s.elCasesOwlSlider.bind('prev.owl.carousel', function (e) {
                var slideCurrent = parseInt(s.elCasesSlideCurrent.text());

                if (slideCurrent > 1) {
                    s.elCasesSlideCurrent.text(slideCurrent - 1);
                }
                else {
                    return;
                }
            });
            s.elCasesOwlSlider.bind('next.owl.carousel', function (e) {
                var slideCurrent = parseInt(s.elCasesSlideCurrent.text());

                if (slideCurrent < s.elCasesOwlSliderItem.length) {
                    s.elCasesSlideCurrent.text(slideCurrent + 1);
                }
                else {
                    return;
                }
            });
        },

        refreshCasesTopHeight: function () {
            var casesTopHeight = s.elCasesTitle.outerHeight();

            s.elCasesControllers.height(casesTopHeight);
            s.elCasesIllustration.height(casesTopHeight);
        },

        refreshCasesBottomHeight: function () {
            var casesBottomHeight = 0;

            s.elCasesSlider.each(function () {
                var el = $(this);

                casesBottomHeight = (el.outerHeight() > casesBottomHeight) ? el.outerHeight() : casesBottomHeight;
            });

            if (window.outerWidth < 992) {
                s.elCasesImage.height(window.outerWidth / (16 / 9));
            }
            else {
                s.elCasesImage.height(casesBottomHeight);
            }
        }
    };

    $(window).load(function () {
        app.HomeCasesWidget.init();
    });

})();
