(function () {
    'use strict';

    var $ = jQuery.noConflict();

    var self, s;
    app.HomeWidget = {

        settings: {
            isMobile: $('html').hasClass('is-mobile'),
            elDiscover: $('#discover'),
            elDiscoverTitle: $('.section-home--discover-bg--title'),
            elDiscoverPhoto: $('.section-home--discover-photo'),
            didResize: false,

            elDiscoverBlock: $('.section-home--discover-block'),

            elCardContainer: $('.skills-card-2'),
            elCardScroll: $('.skills-card-2-scroll'),

            elTeamTitle: $('.team-mate__left'),
            elTeamIllustration: $('.team-mate__right'),
            elTeamMatePicture: $('.team-mate__picture'),
            elTeamMatePrimaryPicture: $('.team-mate__primary .team-mate__picture'),
            elTeamMatePrimaryText: $('.team-mate__primary .team-mate__text'),
            elTeamMateOthersPicture: $('.team-mate__others .team-mate__picture'),
            elTeamMateOthersText: $('.team-mate__others .team-mate__text'),

            elBlog: $('.news-list'),

            elColHeight: $('.col-height')
        },

        init: function () {
            self = this;
            s = this.settings;

            this.bindUIActions();

            this.refreshDiscoverPhotoHeight();

            this.refreshDiscoverBlockHeight();

            this.refreshTeamTopHeight();
            this.refreshTeamMatePrimaryPictureHeight();
            this.refreshTeamMateOthersPictureHeight();
            this.refreshColHeight();

            s.elCardContainer.bind('mouseover', function () {
                $(this).find('.skills-card-2-scroll').customScroll({
                    horizontal: false
                });
            });
        },

        bindUIActions: function () {
            var myEfficientResize = app.debounce(function () {
                self.refreshDiscoverPhotoHeight();
                self.refreshDiscoverBlockHeight();
                self.refreshTeamTopHeight();
                self.refreshTeamMatePrimaryPictureHeight();
                self.refreshTeamMateOthersPictureHeight();
                self.refreshColHeight();
            }, 250);

            window.addEventListener('resize', myEfficientResize);
        },

        refreshDiscoverPhotoHeight: function () {
            var discoverHeight = s.elDiscover.find('.section-home--discover-bg--desc').outerHeight() + s.elDiscover.find('.section-home--discover-subdesc').outerHeight(),
                discoverTitleHeight = s.elDiscoverTitle.outerHeight();

            if (window.outerWidth > 991) {
                s.elDiscoverPhoto.outerHeight(discoverHeight - discoverTitleHeight);
            }
            else {
                s.elDiscoverPhoto.outerHeight(discoverTitleHeight);
            }
        },

        refreshDiscoverBlockHeight: function () {
            var discoverBlocksHeight = 0;

            s.elDiscoverBlock.css('min-height', 'initial');

            if (window.outerWidth > 767) {
                s.elDiscoverBlock.each(function () {
                    discoverBlocksHeight = ($(this).outerHeight() > discoverBlocksHeight) ? $(this).outerHeight() : discoverBlocksHeight;
                });

                s.elDiscoverBlock.css('min-height', discoverBlocksHeight);
            }
            else {
                return;
            }
        },

        refreshTeamTopHeight: function () {
            var teamTopHeight = s.elTeamTitle.outerHeight();

            s.elTeamIllustration.height(teamTopHeight);
        },

        refreshTeamMatePrimaryPictureHeight: function () {
            if (window.outerWidth > 1199) {
                s.elTeamMatePrimaryPicture.css('min-height', s.elTeamMatePrimaryText.outerHeight());
            }
            else {
                s.elTeamMatePrimaryPicture.css('min-height', '500px');
                return;
            }
        },

        refreshTeamMateOthersPictureHeight: function () {
            var teamMateOthersPictureHeight = 0;

            s.elTeamMateOthersText.css('min-height', '0');

            s.elTeamMateOthersText.each(function () {
                app.console($(this).outerHeight());
                teamMateOthersPictureHeight = ($(this).outerHeight() > teamMateOthersPictureHeight) ? $(this).outerHeight() : teamMateOthersPictureHeight;

            });

            if (window.outerWidth > 767) {
                s.elTeamMateOthersPicture.css('min-height', teamMateOthersPictureHeight);
                s.elTeamMateOthersText.css('min-height', teamMateOthersPictureHeight);
            }
            else {
                s.elTeamMateOthersPicture.css('min-height', '500px');
                return;
            }
        },

        refreshColHeight: function () {
            s.elColHeight.each(function () {
                var el = $(this),
                    twin = el.parent().find('.col-same-height'),
                    height = (el.outerHeight() > twin.find('> *').outerHeight()) ? el.outerHeight() : twin.find('> *').outerHeight() + 20;

                if (window.outerWidth > 991) {
                    twin.css('min-height', height);
                }
                else {
                    twin.css('min-height', 'initial');
                }
            });
        }
    };

    $(window).load(function () {
        app.HomeWidget.init();
    });

})();
